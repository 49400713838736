<!-- 订单详情 -->
<template>
  <div class="order-detail">
    <div class="content">
      <div class="goods-pic">
        <img :src="taskInfo.imageUrl" alt="" />
      </div>
      <div class="goods-box">
        <div class="goods-name font-bold">{{ taskInfo.title }}</div>
        <div class="goods-data-line">
          <div class="label">{{ taskInfo.author }}</div>
          <div class="data">
            <img src="../assets/images/icon-eye.svg" alt="" />
            <div class="num">{{ taskInfo.clicks }}</div>
          </div>
        </div>
        <div class="goods-data-line">
          <div class="label">{{ $i18n.t("gd_chain_address") }}{{ taskInfo.address }}</div>
          <div class="data">
            <img src="../assets/images/icon-collet.svg" alt="" />
            <div class="num">{{ taskInfo.likes }}</div>
          </div>
        </div>
      </div>
      <div class="time-box">
        <div class="tip">
          <span>{{ $i18n.t("gd_display") }} - {{ $i18n.t("gd_remainder") }}</span>
          <div class="icon-que">
            <!-- <img src="../assets/images/icon-quer-nor.svg" alt="" /> -->
          </div>
        </div>
        <!-- <div class="tip">
          <span>{{ $i18n.t("gd_ended") }}</span>
        </div> -->
        <div class="count-down">
          <van-count-down :time="time">
            <template #default="timeData">
              <div class="block font-b">
                {{ timeData.days > 9 ? timeData.days : "0" + timeData.days }}
              </div>
              <div class="colon">:</div>
              <div class="block font-b">
                {{ timeData.hours > 9 ? timeData.hours : "0" + timeData.hours }}
              </div>
              <div class="colon">:</div>
              <div class="block font-b">
                {{ timeData.minutes > 9 ? timeData.minutes : "0" + timeData.minutes }}
              </div>
              <div class="colon">:</div>
              <div class="block font-b">
                {{ timeData.seconds > 9 ? timeData.seconds : "0" + timeData.seconds }}
              </div>
            </template>
          </van-count-down>
        </div>
        <!-- <div class="sub-tip">还没有出价，成为第一个出价的人!</div> -->
      </div>
      <div class="sala-data">
        <div class="data-item">
          <div class="word">{{ $i18n.t("od_my_bid") }}</div>
          <div class="price">
            <img src="../assets/images/icon-eth-s.svg" alt="" />
            <div class="num font-b">{{ taskInfo.xPrice }} {{ currency }}</div>
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("gd_income") }}</div>
          <div class="price">
            <img src="../assets/images/icon-eth-s.svg" alt="" />
            <div class="num font-b">{{ taskInfo.xRate }} {{ currency }}</div>
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("gd_return") }}</div>
          <div class="price">
            <div class="num font-b">{{ taskInfo.income }} %</div>
          </div>
        </div>
      </div>
      <div class="btn send-btn" @click="goTaskDetail" v-if="!isDisabled">{{ $i18n.t("od_my_continue") }}</div>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import { utcDateSubtract } from '../utils/day'
import config from "@/config";
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  data() {
    return {
      currency: config.currency_eth,
      userInfo: {},
      orderInfo: {},
      taskInfo: {},
      orderId: null,
      taskId: null,
      time: 0,
      timeData: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      isDisabled: false,
      isEnded: false,
    };
  },
  created() {
    this.orderId = this.$route.query.id ? this.$route.query.id : "";
    this.loadInfo();
    // console.log(config);
  },
  methods: {
    substr6(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    loadInfo() {
      let that = this;
      this.$http.getEthPrice().then((_res) => {
        if (_res.code === 0) {
          const price = _res.data.ethPrice.price;
          this.$http.getOrderInfo({id: parseInt(this.orderId)}).then((res) => {
            if (res.code === 0) {
              that.orderInfo = res.data.order;
              that.taskInfo = res.data.task;
              that.taskInfo.xPrice = that.substr6(that.taskInfo.price / price);
              that.taskInfo.xRate = that.substr6(((that.taskInfo.price * that.taskInfo.income * 0.01) / price));
              
              const countTime = utcDateSubtract(that.taskInfo.showAt);
              this.time = countTime > 0 ? countTime : 0;
              // 藏品展示时间小于60分钟不展示出价
              if (countTime < 60 * 60 * 1000) {
                this.isDisabled = true;
              }

              if (countTime < 0 || that.taskInfo.status === 'FINISHED') {
                this.isEnded = true;
                this.time = 0;
              }

              // // 藏品展示时间小于60分钟不展示出价
              // if (+dayjs(that.taskInfo.showAt) - (+dayjs()) < 60 * 60 * 1000) {
              //   this.isDisabled = true;
              // }
              // if (+dayjs(that.taskInfo.showAt) - (+dayjs()) < 0 || that.taskInfo.status === 'FINISHED') {
              //   this.isEnded = true;
              //   this.time = 0
              // }
              
              // const countTime = +dayjs(this.handleTime(that.taskInfo.showAt)) - (+dayjs());
              // this.time = countTime > 0 ? countTime : 0;
            }
          });
        }
      });
    },
    goTaskDetail() {
      this.$router.push({path: "/goods-detail", query: {id: this.taskInfo.id}});
    },
  },
};
</script>

<style lang="less">
.order-detail {
  .content {
    padding: 1.36rem 0.48rem 1.6rem 0.48rem;
    .goods-pic {
      width: calc(100vw - 0.96rem);
      height: calc(100vw - 0.96rem);
      border-radius: 0.48rem;
      overflow: hidden;
      display: flex;
      img {
        width: 100%;
        height: calc(100vw - 0.96rem);
      }
    }
    .goods-box {
      text-align: left;
      margin-top: 0.32rem;
      padding-bottom: 0.15rem;
      border-bottom: 1px solid #f5f5f5;
      .goods-name {
        font-size: 0.32rem;
        color: #333;
        font-weight: bold;
        line-height: 0.48rem;
        margin-bottom: 0.3rem;
      }
      .goods-data-line {
        display: flex;
        justify-content: space-between;
        height: 0.4rem;
        line-height: 0.4rem;
        margin-bottom: 0.15rem;
        .label {
          width: calc(100% - 1.3rem);
          font-size: 0.28rem;
          color: #666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .data {
          width: 1.2rem;
          display: flex;
          justify-content: flex-end;
          img {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.08rem;
            margin-top: 0.03rem;
          }
          .num {
            font-size: 0.24rem;
            line-height: 0.44rem;
            color: #999;
          }
        }
      }
    }
    .time-box {
      border-bottom: 1px solid #f5f5f5;
      .tip {
        padding: 0.3rem 0;
        font-size: 0.24rem;
        line-height: 0.36rem;
        color: #999;
        position: relative;
        .icon-que {
          position: absolute;
          right: 0;
          top: 0.3rem;
        }
      }
      .count-down {
        display: flex;
        justify-content: center;
        margin-bottom: 0.4rem;
        .van-count-down {
          display: flex;
          .block {
            width: 0.8rem;
            height: 0.8rem;
            background: rgba(110, 118, 145, 0.1);
            border-radius: 0.24rem;
            color: #333;
            font-size: 0.4rem;
            line-height: 0.8rem;
            letter-spacing: 3px;
            font-weight: 800;
          }
          .colon {
            font-size: 0.4rem;
            line-height: 0.8rem;
            font-weight: 800;
            margin: 0 0.3rem;
          }
        }
      }
      .sub-tip {
        font-size: 0.28rem;
        color: #999;
        line-height: 0.4rem;
        text-align: left;
        margin-bottom: 0.3rem;
      }
    }
    .sala-data {
      padding: 0.3rem 0;
      display: flex;
      .data-item {
        display: flex;
        flex-direction: column;
        .word {
          flex: 1;
        }
      }
      .data-item {
        .price {
          img {
            margin-top: 0.04rem;
          }
        }
      }
      .data-item:nth-child(1),
      .data-item:nth-child(2) {
        width: 38%;
      }
      .data-item:nth-child(3) {
        width: 24%;
      }
    }
    .send-btn {
      width: 100%;
      font-weight: 400;
      margin: 0.3rem 0;
    }
  }
  .offer-popup {
    width: 80%;
    padding-bottom: 0.48rem;
    .offer-box {
      padding: 0 0.48rem;
      padding-top: 0.8rem;
      position: relative;
      .close {
        position: absolute;
        right: 0.3rem;
        top: 0.2rem;
      }
      .offer-data-list {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #f5f5f5;
        .data-item {
          width: 50%;
          margin-bottom: 0.44rem;
        }
      }
      .offer-tip {
        font-size: 0.24rem;
        color: #999;
        line-height: 0.36rem;
        text-align: left;
        margin-top: 0.3rem;
      }
      .offer-btn {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-top: 0.48rem;
        font-weight: 400;
      }
    }
  }
}
</style>
